import View from '@lernetz/ts-lib/src/core/view/View';
import { renderer } from '@lernetz/ts-lib/src/core/view/Renderer';

export default class BackgroundImage extends View {

    constructor() {
        super({ template:'' } );
    }

    protected init() {
        if ( this.data ) {
            this.node.setAttribute('style', 'background-image:url( ' + renderer.context.imagePath(this.data, "original", this.data.ext ) + ' ) ');
        }
    }
};
