/** @module components/choice-quiz */


/**/
import Model from '@lernetz/ts-lib/src/core/model/Model';
import {View, ViewConfig, ViewRegistrationMap} from '@lernetz/ts-lib/src/core/view/View';
import Repeat from '@lernetz/ts-lib/src/components/Repeat';

import {Choice} from '@lernetz/lernfragen/components/choice-quiz';
import {TextChoice, TextChoiceView} from '@lernetz/lernfragen/components/choice-quiz';

import {Quiz, QuizInputState, QuizVisitor} from '@lernetz/lernfragen/components/quiz';

import {choiceQuizTemplate} from '@lernetz/lernfragen/templates/choice-quiz';


import {ChoiceQuizInitialState, ChoiceQuizType} from '@lernetz/lernfragen/components/choice-quiz';


export interface ChoiceQuizCorrectVisitor<TResult = void> extends QuizVisitor<TResult> {
    visitChoiceQuizCorrect(quiz: ChoiceQuizCorrect): TResult;
}

function isChoiceQuizCorrectVisitor<TResult>(visitor: QuizVisitor<TResult>): visitor is ChoiceQuizCorrectVisitor<TResult> {
    return typeof visitor['visitChoiceQuizCorrect'] !== 'undefined';
}


//allways has a correct input state
export abstract class ChoiceQuizCorrect extends Model implements Quiz {

    protected constructor(initialState: ChoiceQuizInitialState, data: object = {}) {
        super({
            choices: [],
            inputState: 'none',
            type: 'multiple',
            ...initialState,
            ...data
        });

        this.updateInputState();
    }

    private dirty: boolean;

    /**
     * Gets all choices in the quiz.
     */
    public readonly choices: Choice[];

    /** @inheritdoc */
    public readonly inputState: QuizInputState;

    /**
     * Gets the type of this choice quiz (either `'single'` or `'multiple'`).
     */
    public readonly type: ChoiceQuizType;

    public accept<TResult = void>(visitor: QuizVisitor<TResult>): TResult {
        if (isChoiceQuizCorrectVisitor(visitor)) {
            return visitor.visitChoiceQuizCorrect(this);
        }
    }

    public onChoiceClicked(choice: Choice): void {
        this.dirty = true;
        switch (this.type) {

            case 'multiple':
                choice.selected = !choice.selected;
                break;

            case 'single':
                for (const ch of this.choices) {
                    ch.selected = (ch === choice);
                }
                break;
        }
        this.change.dispatch({name: 'inputState', newValue: 'correct', oldValue: 'correct'})
    }

    /**
     * Updates the [[inputState]] property.
     */
    protected updateInputState(): void {
        let inputState: QuizInputState;
        this.set('inputState', 'correct');
    }
}
