import Model from 'ln/model/Model';
import {View, ViewConstructor} from 'ln/view/View';
import {AttributeChangeObservable, AttributeChange} from '@lernetz/ts-lib/src/core/model/Model';
import {SolutionInputAnswer as AnswerTemplate } from '../../templates/components/solutioninput';
import { CanShowCorrectness } from '@lernetz/lernfragen/components/quiz';
import { Signal, Observable } from '@lernetz/ts-lib/src/core/signal/Signal';

export class SolutionInputAnswer extends  Model implements CanShowCorrectness {
    correct: boolean;
    answered: boolean;
    text: string;
    solutions: [{solution: string}];
    showCorrectness: boolean;
    //change: Signal<AttributeChange>; //wozu brauchts das, wenn vom Model geerbt wird?
    

    constructor(data: {correct, answered, text, solutions, showCorrectness }) {
        super(data)
    }

    public updateText(e) {
        this.answered = true;
        let text = this.text;
        this.text = e.target.value;
        this.correct = this.solutions.filter( ( item ) => item.solution.toLowerCase() === this.text.toLowerCase() ).length > 0 ? true : false ;
        this.change.dispatch( {name: text, newValue: this.text, oldValue:text} );
    }
}

export class SolutionInputAnswerView extends View {
    constructor() {
        super({template: AnswerTemplate })
    }
}