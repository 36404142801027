import Model from 'ln/model/Model';
import {View, ViewConstructor} from 'ln/view/View';
import {setup} from 'ln/setup/Setup';
import {ViewDefinition} from 'ln/view/View';
import {AttributeChangeObservable} from '@lernetz/ts-lib/src/core/model/Model';

import Template from '@lernetz/ts-lib/src/core/view/Template';
import { renderer } from '@lernetz/ts-lib/src/core/view/Renderer';

import {Quiz, QuizInputState, QuizVisitor} from '@lernetz/lernfragen/components/quiz';


import {SolutionInput as template} from '../../templates/components/solutioninput';

import {SolutionInputAnswer } from './SolutionInputAnswer';
import { CanShowCorrectness } from '@lernetz/lernfragen/components/quiz';


export class SolutionInput extends Model  implements Quiz {

    public inputState: QuizInputState;

    public readonly answers: SolutionInputAnswer[];
    public readonly totalSolutions: number;
    public readonly elements: [];
    

     constructor(initialState: SolutionInputInitialState, data: object = {}) {
        super({
            answers:[],
            inputState: 'none',
            ...initialState,
            ...data
        });


        for (let step = 0; step < this.totalSolutions; step++) {
            let answer = new SolutionInputAnswer(  { correct: false, answered: false, text: '', solutions: this.elements, showCorrectness: false } );
            answer.change.subscribe( ()=> this.updateInputState( ) );
            this.answers.push( answer )
        }

        //this.updateInputState();
    }

    public updateInputState() {
        // find duplicates
        // todo: make it nice...
        this.answers.map( (item, index) => {
            let found = this.answers.filter( (inner) => {
                return item.text.toLowerCase() === inner.text.toLowerCase();
            })
            if (found.length > 1) {
                found.forEach( (duplicate, index) => {
                    if (index > 0) {
                        duplicate.correct = false;
                    }
                })
            }
        });
        
        this.inputState = this.answers.some(answer => answer.correct === false) ? 'incorrect' : 'correct';

    }

    public accept<TResult = void>(visitor: QuizVisitor<TResult>): TResult {
        if (isSolutionInputAnswerVisitor(visitor)) {
            return visitor.visitSolutionInputAnswer(this);
        }
    }
}

function isSolutionInputAnswerVisitor<TResult>(visitor: QuizVisitor<TResult>): visitor is SolutionInputAnswerVisitor<TResult> {
    return typeof visitor['visitSolutionInputAnswer'] !== 'undefined';
}

export interface SolutionInputAnswerVisitor<TResult = void> extends QuizVisitor<TResult> {
    visitSolutionInputAnswer(quiz: SolutionInput): TResult;
}

export interface SolutionInputInitialState {
    /**
     * Sets the available choices for the choice quiz.
     */
    answers: [];
}


export class SolutionInputView extends View {
    public constructor() {
        super({ template: template });
    }
}