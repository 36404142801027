
import { Signal } from '@lernetz/ts-lib/src/core/signal/Signal';

import Model from 'ln/model/Model';

import {ResourceList as template} from 'templates/components/elements';


import View from 'ln/view/View';


export  class ResourceList extends Model {

    public constructor(dto) {
        super({
            ...dto
        });
        
         
    }
}

export class ResourceListView extends View<ResourceList> {
    public constructor() {
        super({template: template })
    }
}