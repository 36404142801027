import View from '@lernetz/ts-lib/src/core/view/View';
import { renderer } from '@lernetz/ts-lib/src/core/view/Renderer';
import {setup, SetupConfig} from 'ln/setup/Setup';

export default class StaticImage extends View {

    constructor() {
        super({ template:'' } );
    }

    protected init() {
        if (this.data ) {
            this.node.setAttribute('src', setup.route('image', {file_name: this.data}).url() );
            
        }
        
        
    }
};
