import Model from 'ln/model/Model';
import {ViewDefinition} from 'ln/view/View';
import {StorySolution as template} from 'templates/components/storysolution';

export class StorySolution extends Model {

    public image:{ file_name?:string };
    public postcards: {};

    public constructor(dto: object) {
        super({
            ...dto
        });
        if( !this.image ) this.image = {};
    }
}

export const StorySolutionView: ViewDefinition<StorySolution> = {
    template,
};
