
import {AudioPlayer, AudioElement} from '../util/AudioPlayer'


class AudioServiceProvider {
    
    public registered: AudioElement[]

    public constructor() {
        this.registered = [];
    }

    public register( audio: AudioElement ) {
        if (this.registered.indexOf(audio) < 0) {
            this.registered.push(audio);
        }
    }

    public play( audio: AudioElement ) {
        this.stopAll();
        audio.audio.play();
        audio.state = 'playing'
        audio.change.dispatch();
    }

    public pause( audio: AudioElement ) {
        audio.audio.pause();
        audio.state = 'stopped';
        audio.change.dispatch();
        
    }


    public stopAll():void {
        this.registered.forEach( (audio)=> {
            this.pause(audio);
        })
    }



}



export var AudioService = new AudioServiceProvider();