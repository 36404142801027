import Model from 'ln/model/Model';
import {ViewDefinition} from 'ln/view/View';
import {StoryImage as template} from 'templates/components/storyimage';

export class StoryImage extends Model {

    public image:{ file_name?:string };

    public constructor(dto: object) {
        super({
            ...dto
        });

        if( !this.image ) this.image = {};
    }

}

export const StoryImageView: ViewDefinition<StoryImage> = {
    template,
};
