import { Resolvable } from 'ln/components/Resolve';
import Model from 'ln/model/Model';
import View from '@lernetz/ts-lib/src/core/view/View';
import { renderer } from '@lernetz/ts-lib/src/core/view/Renderer';

import {Footer as template} from 'templates/components/footer';
import {setup, SetupConfig} from 'ln/setup/Setup';


export class Footer extends Model {
    activeLanguage: string;

    public constructor() {
        super({
            activeLanguage: '',
            
        });
        this.activeLanguage =  setup.data('activeLanguage');
    }
}

export  class FooterView extends View {

    constructor() {
        super({ template:template, data: new Footer()} );
    }

    protected init() {
    }
};

