/** @module components/visitors */


/**/
import {Subscription} from '@lernetz/ts-lib/src/core/signal/Signal';

import  {ShowCorrectnessOfGivenAnswersQuizVisitor}  from '@lernetz/lernfragen/components/visitors/ShowCorrectnessOfGivenAnswersQuizVisitor'

import {QuizVisitor, canShowCorrectness} from '@lernetz/lernfragen/components/quiz';

import { SolutionInput } from '../solutioninput/SolutionInput';

import {ChoiceQuizCorrect} from '../multiplechoicecorrect/ChoiceQuizCorrect';

/**
 * Visitor that will set [[CanShowCorrectness.showCorrectness]] of all answers (choices) given by the user.
 *
 * Because given answers can change over time, this visitor will have to observe all answers (choices)
 * to be able to keep `showCorrectness` properly in sync. The visitor will therefore return a `Subscription`
 * that you can use to tell it to stop observing.
 *
 * If you want this visitor to set `showCorrectness` only for the current state, and not keep updating it,
 * perform an unsubscribe immediately.
 *
 * @example
 *```typescript
 *
 * const showCorrectnessSyncing = new ShowCorrectnessOfGivenAnswersQuizVisitor().visit(quiz);
 * ...
 * showCorrectnessSyncing.unsubscribe();
 *```
 */
export class CustomizedShowCorrectnessOfGivenAnswersQuizVisitor extends ShowCorrectnessOfGivenAnswersQuizVisitor {

    public visitChoiceQuizCorrect(quiz: ChoiceQuizCorrect): Subscription {
        const subscriptions: Subscription[] = [];

        return {
            unsubscribe: function() {
                for (const subscription of subscriptions) {
                    subscription.unsubscribe();
                }
            }
        }
    }
    
    public visitSolutionInputAnswer(quiz: SolutionInput): Subscription {

        const subscriptions: Subscription[] = [];

        for (const answer of quiz.answers) {
            if (canShowCorrectness(answer)) {
                subscriptions.push(answer.change.filter(({ name }) => name === 'answered')
                                                .subscribe(() => answer.showCorrectness = answer.answered));
                answer.showCorrectness = answer.answered;
            }
        }

        return {
            unsubscribe: function() {
                for (const subscription of subscriptions) {
                    subscription.unsubscribe();
                }
            }
        }
    }

}
