
import {View, ViewConstructor} from 'ln/view/View';
import {ViewDefinition} from 'ln/view/View';
import {Navigation as template} from 'templates/components/navigation';

import Model from 'ln/model/Model';
import {setup, SetupConfig} from 'ln/setup/Setup';

export class Navigation extends Model {

    public naviState: string;
    public activeLang: string;
    public langMenuOpen: boolean;
    public displayBack: string; //cant set booleans in the view with data-test="true"

    public constructor( dto ) {
        super({
            naviState: 'closed',
            langMenuOpen: false,
            activeLang: {id: 'de', isActive: true},
            displayBack: 'false',
            ...dto
        });
        

        this.activeLang = setup.data('languages').find( (lang) => {
            return lang.isActive === true;
        });
    }

    selectLanguage(lang:string) {
        window.location.href = location.protocol + '//' + location.host + location.pathname + '?lang=' + lang;
        //window.location.href = setup.route('setLanguage', {id: lang}).url();
    }

    public toggleNavigation() {
        this.naviState = (this.naviState === 'closed') ? 'opened' : 'closed';
    }
}


export const NavigationView: ViewDefinition<Navigation> = {
    getData: () => new Navigation({}),
    template,
};


