import {node} from 'ln/node/Node';
import View from 'ln/view/View';

export class PlaceholderView extends View<any> {

    public constructor() {
        super({ template: '<div class="Placeholder"></div>' })
    }

    protected init() {
        this.node.appendChild(node(`<pre>${this.data.modelName}</pre>`).native);
    }
}
