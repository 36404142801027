
import { Signal } from '@lernetz/ts-lib/src/core/signal/Signal';
import Model from 'ln/model/Model';

import {LinkItem as template} from 'templates/components/elements';


import View from 'ln/view/View';


export  class LinkItem extends Model {

    public constructor(dto) {
        super({
            ...dto
        });
    }
}

export class LinkItemView extends View<LinkItem> {
    public constructor() {
        super({template: template })
    }
}