import Lang from 'ln/lang/Lang';
import {renderer} from 'ln/view/Renderer';
import {scan} from 'ln/view/View';
import {setup, SetupConfig} from 'ln/setup/Setup';
import Request from 'ln/request/Request';

import {viewRegistrations} from './components/register';


// export function load(dataUri: string) {
//     Request.get(dataUri).load().then(init);
// }

export function init(data: SetupConfig) {
    renderer.logging = true;
    setup.init(data);

    renderer.context.setup = setup;

	renderer.context.imagePath = function(image: { file_name: string }, preset: string = 'medium', ext: string = 'png') {
        if( !image ) return 'https://via.placeholder.com/233x150/F0F0F0/000000?text=Bild fehlt';
        return decodeURIComponent(setup.route('asset', { file_name: image.file_name, preset, ext }).url());
    }


    Lang.add(setup.data('lang'));

    scan(document.body, viewRegistrations);

    // window.history.pushState = new Proxy(window.history.pushState, {
    //     apply: (target, thisArg, argArray) => {
    //       // trigger here what you need
    //       console.log(target)
    //       console.log(thisArg)
    //       console.log(argArray[0]['title'])

    //       //return target.apply(thisArg, argArray);
    //     },
    //   });

}


export function trackPageView( pageName: string) {

    window['dataLayer'].push({
        'event': 'page_change',  // static Event Name
        'virtual_pageview': 'true',   // SPA route change indicator, boolean true/false
        'virtual_page_name': pageName,  // SPA route change/transition new page name/page title
        'virtual_url': 'https://reise-abenteuer.ch',  // SPA route change new URL
        'page_name_language_neutral': pageName,   // required
        //'page_url_language_neutral': '<<variable input>>',  // required
        'page_url_language_neutral': 'https://reise-abenteuer.ch',  // required
      });

}
