
import { MultipleChoiceQuizView, TextChoice, TextChoiceView } from '@lernetz/lernfragen/components/choice-quiz';
import {customizedMultipleChoiceTemplate as template } from '../../templates/components/quiz-overwrite'

export class CustomizedMultipleChoiceQuizView extends MultipleChoiceQuizView {
    constructor() {
        super({ registrations: CustomizedMultipleChoiceQuizView.createRegistrations() })
    }
    
    static createRegistrations() {
        const registrations = MultipleChoiceQuizView.createRegistrations('multiple');
        registrations.set(TextChoice, () => new TextChoiceView('multiple', { template: template}));
        return registrations;
    }
}