
import { Signal } from '@lernetz/ts-lib/src/core/signal/Signal';
import {ViewDefinition} from 'ln/view/View';
import {SinglePage as template} from 'templates/components/singlepage';

import {Page} from '../page/Page';
import View from 'ln/view/View';

export  class SinglePage extends Page {

    public readonly navigateBack: Signal<void>;
    public readonly slug: string;
    // public readonly elements: [];

    public constructor(dto) {
        super({
            id: '',
            selected: false,
            ...dto
        });
        
         this.navigateBack = new Signal<void>();
    }
}

export class SinglePageView extends View<SinglePage> {
    public constructor() {
        super({template: template })
    }
}