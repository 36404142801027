
import { Signal } from '@lernetz/ts-lib/src/core/signal/Signal';
import {ViewDefinition} from 'ln/view/View';
import Model from 'ln/model/Model';

import {TitleElement as template} from 'templates/components/elements';


import View from 'ln/view/View';


export  class TitleElement extends Model {

    public level: string;
    public margin: string;
    public constructor(dto) {
        super({
            level: 1,
            ...dto
        });

        switch( this.level ) {
            case "1" :
                this.margin = '-m-bottom-l';
                break;
            case "2":
                this.margin = '-m-bottom';
                break;
            case "3":
                this.margin = '-m-bottom';
                break;
            default:
                this.margin = '-m-bottom';

        }
        
         
    }
}

export class TitleElementView extends View<TitleElement> {
    public constructor() {
        super({template: template })
    }
}