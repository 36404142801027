
import { Signal } from '@lernetz/ts-lib/src/core/signal/Signal';
import Model from 'ln/model/Model';

import {DownloadItem as template} from 'templates/components/elements';


import View from 'ln/view/View';


export  class DownloadItem extends Model {

    public constructor(dto) {
        super({
            ...dto
        });
        
         
    }
}

export class DownloadItemView extends View<DownloadItem> {
    public constructor() {
        super({template: template })
    }
}