import View from "@lernetz/ts-lib/src/core/view/View";
import Model from "@lernetz/ts-lib/src/core/model/Model";
import { Signal } from "@lernetz/ts-lib/src/core/signal/Signal";

import { dropdown } from 'templates/components/dropdown'

export class DropDownView extends View<DropDown> {

    private closeHandler:(event) => void;

    constructor( config? ) {

        super( { data: new DropDown(), template:dropdown, ...config } );

        // handler to close dropdown from everywhere
        this.closeHandler = ( event ) => {
            this.data.open = false;
        }


    }

    init() {

        // swallow click event
        this.node.addEventListener( 'click', ( event ) => {
            event.stopImmediatePropagation();
        });

        // listen on list open
        this.data.change.filter( ( change ) => change.name == 'open' && this.data.open ).subscribe( this.onOpen.bind( this ) );

        // listen on list close
        this.data.change.filter( ( change ) => change.name == 'open' && !this.data.open ).subscribe( this.onClose.bind( this ) );
    }

    onOpen() {
        document.addEventListener( 'click', this.closeHandler );
        this.node.querySelector('.dropdown-bottom').scrollIntoView()
    }

    onClose() {
        document.removeEventListener( 'click', this.closeHandler );
    }

}

export class DropDown extends Model {

    public items:Array<any>;
    public open: boolean;
    public selection: any;

    public selected:Signal<any> = new Signal();


    constructor() {
        super( { open:false, items:[], selection:null } );
    }


    select( item:any ) {
        this.selection = item;
        this.open = false;
        this.selected.dispatch( item );
    }

    getIndex( item ) {
        return this.items.indexOf( item );
    }
}
