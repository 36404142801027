
import {ViewConfig} from '@lernetz/ts-lib/src/core/view/View';

import {Choice} from '@lernetz/lernfragen/components/choice-quiz';

import {MultipleChoiceQuizInitialState} from '@lernetz/lernfragen/components/choice-quiz'; 
import {ChoiceQuizView} from '@lernetz/lernfragen/components/choice-quiz';
import {ChoiceQuizCorrect} from './ChoiceQuizCorrect';


export class MultipleChoiceCorrectQuiz extends ChoiceQuizCorrect {

    /**
     * Initializes a new instance of the [[MultipleChoiceQuiz]] class.
     *
     * @param initialState Specifies the initial state for the new instance.
     * @param data An optional object to be passed through to the base class' ([[Model]]) constructor.
     */
    public constructor(initialState: MultipleChoiceQuizInitialState, data: object = {}) {
        super({ type: 'multiple', ...initialState }, data);
    }
}
