import View from '@lernetz/ts-lib/src/core/view/View';
import { renderer } from '@lernetz/ts-lib/src/core/view/Renderer';

export default class Image extends View {

    constructor() {
        super({ template:'' } );
    }

    protected init() {
        if (this.data ) {
            this.node.setAttribute('src', renderer.context.imagePath(this.data, this.data.preset ? this.data.preset : 'large', this.data.ext ? this.data.ext : 'png'));
        }
        
        
    }
};
