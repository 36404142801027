import register from 'ln/setup/register';

import Html from 'ln/components/Html';
import Repeat from 'ln/components/Repeat';
import Image from './util/Image';
import Show from '@lernetz/ts-lib/src/components/Show';


import BackgroundImage from './util/BackgroundImage';
import StaticImage from './util/StaticImage';

import { SingleChoiceQuiz, SingleChoiceQuizView, MultipleChoiceQuiz, MultipleChoiceQuizView, TextChoice, TextChoiceView } from '@lernetz/lernfragen/components/choice-quiz';

import { CustomizedSingleChoiceQuizView } from './quiz-overwrite/CustomizedSingleChoiceQuizView';
import { CustomizedMultipleChoiceQuizView } from './quiz-overwrite/CustomizedMultipleChoiceQuizView';

import { ClozeQuiz, ClozeQuizView, createClozeQuizFromText } from '@lernetz/lernfragen/components/cloze-quiz';
import { HotSpotQuiz, HotSpotQuizView, RectangularHotSpotArea } from '@lernetz/lernfragen/components/hot-spot-quiz';
import ImageModel from "./image/ImageModel";
import {Image as imageTemplate } from "../templates/components/image";
import { CustomizedClozeQuizView } from './quiz-overwrite/CustomizedClozeQuizView';
import {AudioService} from './services/AudioService';


import {PlaceholderView} from './util/Placeholder';

import {StoryPage, StoryPageView} from './storypage/StoryPage';
import {StoryText, StoryTextView} from './storytext/StoryText';
import {StoryQuiz, StoryQuizView} from './storyquiz/StoryQuiz';
import {StorySolution, StorySolutionView} from './storysolution/StorySolution';
import {StoryImage, StoryImageView} from './storyimage/StoryImage';
import {Chapter, ChapterView} from './chapter/Chapter';

import {Footer, FooterView} from './footer/Footer';

import {QuizPresenter, QuizPresenterView } from './quizpresenter/QuizPresenter';


import {AudioPlayer, AudioPlayerView} from './util/AudioPlayer';

import {StoryPreview, StoryPreviewView} from './startpage/StoryPreview';

import {Navigation, NavigationView} from './navigation/Navigation';
import { DropDownView } from './dropdown/DropDown';

import {StartPage, StartPageView} from './startpage/StartPage';
import {SinglePage, SinglePageView} from './singlepage/SinglePage';

import {TitleElement, TitleElementView} from './elements/TitleElement';
import {ParagraphElement, ParagraphElementView} from './elements/ParagraphElement';
import {ImageElement, ImageElementView} from './elements/ImageElement';

import {ResourceList, ResourceListView} from './elements/ResourceList';
import {LinkItem, LinkItemView} from './elements/LinkItem';
import {DownloadItem, DownloadItemView} from './elements/DownloadItem';

import {setup} from 'ln/setup/Setup';
import {SiteView, Site} from './site/Site';

import { SolutionInput, SolutionInputView } from './solutioninput/SolutionInput';
import { SolutionInputAnswer, SolutionInputAnswerView  } from './solutioninput/SolutionInputAnswer';

import { MultipleChoiceCorrectQuiz } from './multiplechoicecorrect/MultipleChoiceCorrectQuiz';
import {Postcard, PostcardView, DropDown} from './postcard/Postcard';

export const {viewRegistrations} = register(({ model, view }) => {

    view('default', PlaceholderView);
    view('html', Html);
    view('repeat', Repeat);
    view( 'Image', Image );
    view('show', Show);

    view( 'BackgroundImage', BackgroundImage );
    view( 'StaticImage', StaticImage );

    view('Navigation', NavigationView);
    view('DropDown', DropDownView );

    //test for new start page route (app can get deleted afterward)
    //this is done via data data attribute in the blade template
    //const getSite = () => setup.data('data') as Site;
    view('Site', SiteView);
    model('App\\Site',Site);

    model('App\\StartPage', StartPage).view(StartPageView);
    view('StoryPreview', StoryPreviewView);

    model('App\\SinglePage', SinglePage).view(SinglePageView);

    view('Footer', FooterView);


    model('App\\TitleElement', TitleElement).view(TitleElementView);
    model('App\\ParagraphElement', ParagraphElement).view(ParagraphElementView);
    model('App\\ImageElement', ImageElement).view(ImageElementView);
    model('App\\ResourceList', ResourceList).view(ResourceListView);
    model('App\\DownloadItem', DownloadItem).view(DownloadItemView);
    model('App\\LinkItem', LinkItem).view(LinkItemView);


    //todDo: audioData in AudioPlayer Model...
    //no model key ?
    view('Audio', AudioPlayerView)

    model('App\\StoryPage', StoryPage).view(StoryPageView);
    model('App\\Chapter', Chapter).view(ChapterView);
    model('App\\StoryText', StoryText).view(StoryTextView);
    model('App\\StoryQuiz', StoryQuiz).view(StoryQuizView);
    model('App\\StoryImage', StoryImage).view(StoryImageView);
    model('App\\Postcard', Postcard).view(PostcardView);
    model('App\\StorySolution', StorySolution).view(StorySolutionView);
    model('App\\PostcardDropDown',DropDown );



    //the quiz
    model( 'App\\ChoiceAnswer', (json:any) => new TextChoice( {  text:json.text, correct:json.correct || false }));
    //not uses yet, but gives the possibility to overwrite the result in the template for the MultipleChoiceCorrectQuiz
    model( 'App\\ChoiceAnswerCorrect', (json:any) => new TextChoice( {  text:json.text, correct:json.correct || false }));

    model('App\\SingleChoice', (json:any) => {
        const quiz = new SingleChoiceQuiz({ choices: json.answers || [] });
        return new QuizPresenter(quiz, json.title,  json.question, json.image, json.posFeedback, json.negFeedback, json.infoText );
    });
    view(SingleChoiceQuiz, CustomizedSingleChoiceQuizView)

    model('App\\MultipleChoice', (json:any) => {
        const quiz = new MultipleChoiceQuiz({ choices: json.answers || [] });
        return new QuizPresenter(quiz, json.title,  json.question, json.image, json.posFeedback, json.negFeedback );
    });
    view(MultipleChoiceQuiz, CustomizedMultipleChoiceQuizView);

    model('App\\MultipleChoiceCorrect', (json:any) => {
        const quiz = new MultipleChoiceCorrectQuiz({ choices: json.answers || [] });
        return new QuizPresenter(quiz, json.title,  json.question, json.image, json.posFeedback, json.posFeedback );
    });
    view(MultipleChoiceCorrectQuiz, CustomizedMultipleChoiceQuizView);

    model('App\\DropDown', (json:any) => {
        const quiz = createClozeQuizFromText(json.text);
        return new QuizPresenter(quiz, json.title, json.question, json.image, json.posFeedback, json.negFeedback );
    });
    view(ClozeQuiz, CustomizedClozeQuizView ); //select-span view = dropdown

    model( 'App\\Image', ImageModel).view({ template: imageTemplate });
    // view( 'ImageElement', {
    //     template: imageTemplate,
    // });

    model('App\\Hotspot', (json:any) => {
        const background = new ImageModel(json);
        const hotSpotAreas = json.hotspot_areas.map(hsa => new RectangularHotSpotArea( {  topLeft: { x: hsa.left, y: hsa.top }, bottomRight: { x:  hsa.left + hsa.width, y: hsa.top + hsa.height } }));
        const quiz = new HotSpotQuiz({ background , hotSpotAreas, spots: [] }, json);

        return new QuizPresenter(quiz, json.title, json.question, null, json.posFeedback, json.negFeedback );
    });
    view(HotSpotQuiz, HotSpotQuizView);

    //model(DragAndDropQuiz).view(() => new DragAndDropQuizView({ template: customizedDragAndDropQuizTemplate }));

    // model(TextChoice).view( ()=> new class extends TextChoiceView {
    //     constructor() {
    //         super(test);
    //     }
    // } );

    model( 'App\\SolutionInput', (json:any) => {
        let quiz = new SolutionInput(json)
        return new QuizPresenter(quiz, json.title, json.question, json.image, json.posFeedback, json.negFeedback );
    });

    view(SolutionInput, SolutionInputView);
    view(SolutionInputAnswer, SolutionInputAnswerView);




    view(QuizPresenter, QuizPresenterView);


});
