import { Resolvable } from 'ln/components/Resolve';
import Model from 'ln/model/Model';
import View from 'ln/view/View';
import {setup} from 'ln/setup/Setup';

import {mapper} from 'ln/model/Mapper'
import {ChoiceQuiz} from '@lernetz/lernfragen/components/choice-quiz';
import {Quiz, QuizInputState} from '@lernetz/lernfragen/components/quiz';

import {Signal} from '@lernetz/ts-lib/src/core/signal/Signal';

import {CustomizedShowCorrectnessOfGivenAnswersQuizVisitor as ShowCorrectnessOfGivenAnswersQuizVisitor} from '../quiz-overwrite/CustomizedShowCorrectnessOfGivenAnswerQuizVisitor'


import {ViewDefinition} from 'ln/view/View';

import {QuizPresenter as template} from 'templates/components/quizpresenter';

export class QuizPresenter extends Model  {

    public readonly title: string;
    public readonly question: string;
    public readonly quiz: Quiz;
    public readonly image:{ file_name?:string };
    public readonly posFeedback: string;
    public readonly negFeedback: string;
    public readonly infoText: string;

    public next: Signal<void>;
    public close: Signal<void>;
    public evaluationState: QuizInputState;
    public solutionMode: boolean;
    public isCurrent: boolean;
    public isLast: boolean;
    public quizState: string;
    public isMagnified: boolean;
    public pointState: string;
    public quizFinished: boolean;
    public infoTextOpen: boolean;

    constructor( quiz, title, question, image, posFeedback, negFeedback, infoText = null ) {
        super({
            evaluationState: 'none',
            pointState: 'empty',
            solutionMode: false,
            isCurrent: false,
            isLast: false,
            quizState:'unsolved',
            isMagnified: false,
            quizFinished: false,
            infoTextOpen: false,
            quiz,
            title,
            question,
            image,
            posFeedback,
            negFeedback,
            infoText
        });

        //todo show if funktioniert nicht...
        if (this.image === null) {
            this.image = {};
        }

        if (this.infoText){
            if ( this.title && this.title.includes('[[') && this.title.includes(']]') ) {
                this.title = this.title.replace('[[', '<span class="info-text">');
                this.title = this.title.replace(']]', '</span>');
            }
        }
        this.next = new Signal<void>();
        this.close = new Signal<void>();
        
        this.posFeedback = this.posFeedback ? this.posFeedback : setup.data('lang.posFeedback');
        this.negFeedback = this.negFeedback ? this.negFeedback : setup.data('lang.negFeedback');
        
        
        //if answers is changed after answer-button is pressed, refresh the point navigation state and the feedback
        this.quiz.change.subscribe( (e) => {
            if (this.solutionMode === true) {
                this.evaluationState = this.quiz.inputState;
            }
        })
    }

    public showSolution() {
        this.evaluationState = this.quiz.inputState;
        new ShowCorrectnessOfGivenAnswersQuizVisitor().visit(this.quiz);
        this.solutionMode = true;
    }

    public showNext() {
        this.next.dispatch();
    }

    public toggleMagnify(){
        this.isMagnified = !this.isMagnified;
    }

    public closeQuiz() {
        //show the quiz finished window only on first finishState
        if (this.quizState === 'completed') {
            this.quizFinished = true;
        }
        this.close.dispatch();
    }




}

export class QuizPresenterView extends View<QuizPresenter> {
    public constructor() {
        super({template: template })
    }

    init() {
        let infoText = this.node.querySelector('.info-text');
        if ( infoText ) {
            infoText.addEventListener('click', (e) => {
                this.data.infoTextOpen = true;
            });
        }

        
        //scroll to bottom on answer
        //TODO: THERE ARE TOO MANY EVENTS REGISTERED...
        //seems that the image loads to late...
        this.data.change.subscribe( (e)=>{
            // if (e.name === 'quizFinished') {
                setTimeout( () =>{ 
                    this.node.querySelector('.quiz-bottom').scrollIntoView();
            }, 100);
            //}
            
        })
    }
}


