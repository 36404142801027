import Model from 'ln/model/Model';
import {node} from 'ln/node/Node';
import {Signal} from 'ln/signal/Signal';
import View from 'ln/view/View';
import {setup} from 'ln/setup/Setup';
import {Page} from 'components/page/Page';
import {StartPage} from 'components/startpage/StartPage';
import {StoryPage} from 'components/storypage/StoryPage'
import {SinglePage} from 'components/singlepage/SinglePage';
import {siteTemplate } from 'templates/components/site';
import { AudioService } from 'components/services/AudioService';
import { trackPageView } from 'typescript/Main';

export class Site extends Model {

    public readonly hasStartPage: boolean;
    public readonly pages: Page[];
    public readonly title: string;

    public selectedPage: Page;
    public selectPreviousPage: Signal<void>;
    public storyPages: StoryPage[];
    public singlePages: SinglePage[];
    public readonly isTeacher: boolean;


    public constructor(dto) {
        super({
            pages: [],
            title: '',
            selectedPage: null,
            storyPages:[],
            singlePages:[],
            isTeacher: false,
            ...dto,
        });
        this.pages.forEach((page) => {
            if (page instanceof StoryPage) {
                this.storyPages.push(page);
            }
            if (page instanceof SinglePage) {
                this.singlePages.push(page);
            }
        })

        //for testing by cs
        this.selectStartPage();

        this.selectPreviousPage = new Signal<void>();
        // this.hasStartPage = this.pages.some(p => p instanceof StartPage);


        // for (const page of this.pages) {
            //page.navigateBack.subscribe(() => this.selectPreviousPage.dispatch());
        // }
    }


    // public getPagesForMenuItems(targetAudience: TopicPageTargetAudience): Page[] {
    //     //return this.pages.filter(p => !(p instanceof StartPage) && (!(p instanceof TopicPage) || (p.targetAudience === targetAudience)));
    // }

    public isStartPage(page: Page): boolean {
        return page instanceof StartPage;
    }

    public selectPage(page: Page): void {
        this.selectedPage = page;
    }

    public selectStartPage(): void {
        const startPages = this.pages.filter(p => p instanceof StartPage);
        if (startPages && startPages.length) {
            AudioService.stopAll();
            this.selectPage(startPages[0]);
        }
    }

    // public selectImpressum(): void {
    //     const impressum = this.pages.filter(p => p instanceof SinglePage && p.slug === 'impressum');
    //     if (impressum && impressum.length) {
    //         AudioService.stopAll();
    //         this.selectPage(impressum[0]);
    //     }
    // }

    public selectSlug(slug: string): void{
        const page = this.pages.filter(p => p instanceof SinglePage && p.slug === slug);
        if (page && page.length) {
            AudioService.stopAll();
            this.selectPage(page[0]);
        }
    }

}


export class SiteView extends View<Site> {

    private isPoppingState: boolean;


    public constructor() {
        super({template: siteTemplate })
    }

    init(): void {

        window.addEventListener('scroll', function(e) {
            let navi = document.querySelector('.main-navigation');
            if (navi && !navi.querySelector('.-scrolling') && window.pageYOffset > 1) {
                navi.classList.add('-scrolling');
            }
            else if (navi) {
                navi.classList.remove('-scrolling');
            }
        })


        /*
        this.data.change.filter( (p) => p.name === 'selectedPage').subscribe( (e) => {
            let newSite = e.newValue;
            let oldSite = e.oldValue;
            //does not work in ie11 ?
            node( document.getElementsByTagName( 'html' )[0]).removeClass(oldSite.constructor.name.toLowerCase())
            node( document.getElementsByTagName( 'html' )[0]).addClass(newSite.constructor.name.toLowerCase())
            //document.getElementsByTagName( 'html' )[0].className = newSite.constructor.name.toLowerCase();
        })
        */

        this.setupNavigationHistory();
    }

    private setupNavigationHistory(): void {
        history.replaceState({ selectedPageId: this.data.selectedPage.id }, '');
        this.data.change.filter(p => p.name === 'selectedPage').subscribe(this.onSelectedPageChanged.bind(this));
        this.data.selectPreviousPage.subscribe(() => {
            history.back();
            this.onPopState(history.state);
        });
        window.addEventListener('popstate', e => this.onPopState(e.state));
    }

    private onSelectedPageChanged(): void {
        if (this.isPoppingState) return;
        history.pushState({ selectedPageId: this.data.selectedPage.id, title: this.data.selectedPage['title'] }, '');
        trackPageView(this.data.selectedPage['neutralTitle'] ? this.data.selectedPage['neutralTitle'] : 'Reiseabenteuer');
    }

    private onPopState(state: any): void {
        this.isPoppingState = true;
        try {
            if (state && 'selectedPageId' in state) {
                const pages = this.data.pages.filter(p => p.id === state.selectedPageId);
                if (pages.length) {
                    this.data.selectPage(pages[0]);
                }
            }
        }
        finally {
            this.isPoppingState = false;
        }
    }
}
