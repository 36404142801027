import Model from 'ln/model/Model';
import {ViewDefinition} from 'ln/view/View';
import {StoryText as template} from 'templates/components/storytext';

export class StoryText extends Model {

    public constructor(dto: object) {
        super({
            ...dto
        });
    }

}

export const StoryTextView: ViewDefinition<StoryText> = {
    template,
};
