import Model from 'ln/model/Model';
import {ViewDefinition} from 'ln/view/View';
import {StoryPreview as template} from 'templates/components/startpage';
import { Site } from 'components/site/Site';


export class StoryPreview extends Model {

    public site: Site;
    public constructor(dto: object) {
        super({
            site: null,
            ...dto
        });
    }

}

export const StoryPreviewView: ViewDefinition<StoryPreview> = {
    template,
};
