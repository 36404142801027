import { ClozeQuizView } from '@lernetz/lernfragen/components/cloze-quiz/ClozeQuiz';
import { TextSpanView, TextSpan } from '@lernetz/lernfragen/components/cloze-quiz/TextSpan';
import { SelectSpanView, SelectSpan } from '@lernetz/lernfragen/components/cloze-quiz/SelectSpan';
import Repeat from '@lernetz/ts-lib/src/components/Repeat';
import { ViewRegistrationMap } from '@lernetz/ts-lib/src/core/view/View';
import { ClozeDropDownView } from './ClozeDropDown';

export class CustomizedClozeQuizView extends ClozeQuizView {
    constructor() {
        super({ registrations: CustomizedClozeQuizView.createRegistrations() })
    }

    static createRegistrations() {
        const registrations = new ViewRegistrationMap();
        registrations.set('repeat', Repeat);
        registrations.set( TextSpan, TextSpanView );
        registrations.set( SelectSpan, ClozeDropDownView );
        return registrations;
    }
}
