import View from '@lernetz/ts-lib/src/core/view/View';

import {renderer} from 'ln/view/Renderer';

import Model from 'ln/model/Model';
import {ViewDefinition} from 'ln/view/View';
import {AudioService} from '../services/AudioService';
import {AudioPlayer as template} from 'templates/components/util';
import { Signal } from '@lernetz/ts-lib/src/core/signal/Signal';

export interface AudioElement extends HTMLAudioElement {
    state: string;
    audio: HTMLAudioElement;
    change: Signal<void>;
}

export class AudioPlayer extends Model {
    public audio: HTMLAudioElement;    
    public audioelement: AudioElement;
    public modelName: string;
    public state: string;

    public constructor() {
        super({
            audioelement: {audio:null, state: "stopped", change: null},
            state: "stopped"
        });

        this.audioelement.change = new Signal();
        
        AudioService.register(this.audioelement);

        //is there a better way to keep the data binding?
        this.audioelement.change.subscribe( ()=> {
            this.state = this.audioelement.state;
        })

    }

    public toggleAudio() {
        
        if (this.audioelement.state === 'stopped') {
            AudioService.play( this.audioelement );
        }
        else {
            AudioService.pause( this.audioelement );
        }
    }
}


export  class AudioPlayerView extends View {

    constructor() {
        super({ template:template, data: new AudioPlayer() } ); //thats magic...
    }

    public init() {
        this.data.audioelement.audio = new Audio(renderer.context.imagePath(this.data.audio, 'original', 'mp3'));

        //set state when player ended
        this.data.audioelement.audio.addEventListener('ended', function() {
            AudioService.pause(this.data.audioelement)
        }.bind(this));

    }

};
