
import { Signal } from '@lernetz/ts-lib/src/core/signal/Signal';
import {ViewDefinition} from 'ln/view/View';
import {StartPage as template} from 'templates/components/startpage';

import {Page} from '../page/Page';
import View from 'ln/view/View';
import {setup, SetupConfig} from 'ln/setup/Setup';

export  class StartPage extends Page {

    public readonly navigateBack: Signal<void>;
    activeLanguage: string;

    public constructor(dto) {
        super({
            id: '',
            selected: false,
            activeLanguage: '',
            ...dto
        });
        
         this.navigateBack = new Signal<void>();
         this.activeLanguage =  setup.data('activeLanguage');
    }
}

export class StartPageView extends View<StartPage> {
    public constructor() {
        super({template: template })
    }
}

