
import { SingleChoiceQuizView, TextChoice, TextChoiceView } from '@lernetz/lernfragen/components/choice-quiz';
import {customizedSingleChoiceTemplate as template } from '../../templates/components/quiz-overwrite'

export class CustomizedSingleChoiceQuizView extends SingleChoiceQuizView {
    constructor() {
        super({ registrations: CustomizedSingleChoiceQuizView.createRegistrations() })
    }
    
    static createRegistrations() {
        const registrations = SingleChoiceQuizView.createRegistrations('single');
        registrations.set(TextChoice, () => new TextChoiceView('single', { template: template}));
        return registrations;
    }
}