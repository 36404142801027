import Model from 'ln/model/Model';
import {Signal} from 'ln/signal/Signal';




export abstract class Page extends Model {

    public id: string;
    public selected: boolean;
    
    
    public readonly navigateBack: Signal<void>;

    public constructor(dto) {
        super({
            id: '',
            selected: false,
            ...dto
        });

        this.navigateBack = new Signal<void>();
    }
}
