import Model from 'ln/model/Model';
import {node} from 'ln/node/Node'
import {setup} from 'ln/setup/Setup';

import {View, ViewDefinition} from 'ln/view/View';
import {Chapter as template} from 'templates/components/chapter';

import {StoryQuiz} from '../storyquiz/StoryQuiz';
import {StoryText} from '../storytext/StoryText';
import {StorySolution} from '../storysolution/StorySolution';
import { Signal } from '@lernetz/ts-lib/src/core/signal/Signal';
// import {FullPage} from '../storypage/FullPage';

export class Chapter extends Model {

    
    public elements: StoryText[] | StoryQuiz[] | StorySolution [];
    public quizState: string;
    public index: number;
    public quizOpen: string;
    public chapterAccess: boolean;
    public storyTitle: string;
    // public scrollStart: number;
    
    public constructor(dto: object) {
        super({
            index: null,
            quizState: 'open',
            quizOpen: false,
            chapterAccess: setup.data('data.isTeacher'),
            ...dto
        });
        
        //get the story quiz events
        this.elements.forEach( (element, index)=> {

            if (element instanceof StoryQuiz ) {
                element.change.filter((p)=>{ return p.name === 'quizState'}).subscribe( (p)=>{
                    this.quizState = p.newValue;
                });
                element.change.filter((p)=>{ return p.name === 'quizOpen'}).subscribe( (p)=>{
                    this.quizOpen = p.newValue ;
                });
                // this.scrollStart = element.currentPosition;
            }
        })
    }
}



export class ChapterView extends View<Chapter> {

    public constructor() {
        super({ template: template });
    }

    public init() {

        //on quiz window close...
        this.data.change.filter( (p)=>{ return p.name === 'quizOpen'} ).subscribe( (p) => {
            if (p.newValue === false && this.data.quizState === 'completed')  {
                //show the next chapter
                let nextChapter = document.querySelectorAll('.chapter')[this.data.index + 1];
                nextChapter.classList.remove('_hidden');
                // nextChapter.scrollIntoView(false);
                
                //window.scrollTo(0, this.data.scrollStart) ;
                setTimeout( ()=> {
                    let bound = nextChapter.getBoundingClientRect();
                    window.scrollTo(0, bound.top + 20) ;
                    // window.scrollTo({
                    //     top: bound.top + 20,
                    //     left: 0,
                    //     behavior: 'smooth'
                    // });
                //wait until the next chapter is appended to the view
                }, 200)
            }
        })

    }

}


