
import Model from 'ln/model/Model';
import {setup} from 'ln/setup/Setup';
import {View, ViewConstructor} from 'ln/view/View';
import {ViewDefinition} from 'ln/view/View';
import {StoryPage as template} from 'templates/components/storypage';
import {node} from 'ln/node/Node'
import debounce from 'ln/util/debounce';

import {Chapter, ChapterView} from '../chapter/Chapter';

// import {FullPage} from './FullPage';

import {Page} from '../page/Page';
import { Site } from 'components/site/Site';


export class StoryPage extends Page {

    public elements:Chapter[];
    public site: Site;
    public quizOpen: boolean;
    public isTeacher: boolean;
    // public scrolledBottom: boolean;
    public constructor(dto: object) {
        super({
            quizOpen: false,
            // scrolledBottom: false,
            ...dto
        });

        this.isTeacher = setup.data('data.isTeacher')

        this.elements.forEach( (element, index)=> {
            element.index = index;

            element.change.filter( (e)=> e.name ==='quizOpen').subscribe( (e) => {
                this.quizOpen = e.newValue;
            })
        });
    }
}

export class StoryPageView extends View<StoryPage> {

    public constructor() {
        super({ template: template });
    }

    protected init(): void {
        window.scrollTo(0,0)

        this.data.elements.forEach( (chapter, index) =>{
            //reset the view
            if (index===0) {
                chapter.chapterAccess = true;
            }
            if (chapter.quizState === 'completed') {
                this.data.elements[index + 1].chapterAccess = true;
            }

            chapter.change.filter( (p)=> {return p.name === 'quizState'}).subscribe( (p) => {
                this.data.elements[index + 1].chapterAccess = true;
            });
        })

        
        
        
        // window.addEventListener( 'scroll', function() {
        //     if ( window.innerHeight + window.scrollY === document.body.clientHeight ) {
        //         this.data.scrolledBottom = true;
                
        //         console.log(this, 'bottom')
        //     }
        //     else {
        //         this.data.scrolledBottom = false;
        //     }
        //   }.bind(this));
     }
}

