import Model from 'ln/model/Model';
import {ViewDefinition, View} from 'ln/view/View';
import { renderer } from '@lernetz/ts-lib/src/core/view/Renderer';
import {PostcardTemplate as template} from 'templates/components/postcard';
import {setup, SetupConfig} from 'ln/setup/Setup';
import { HttpRequest } from 'ln/request/Request';

export class Postcard extends Model {


    linkText: string;
    slug: string;
    title: string;
    sender: string;
    scrollPosition: number;
    image: string;

    dropdowns: [{
        name:string;
        choices: [{
            text: string;
        }]
    }]
    id: number;
    openPostcard: boolean;




    public constructor(dto: object) {
        super({
            openPostcard: false,
            dropdowns: [],
            page: null,
            scrollPosition: 0,
            id: null,
            image: '',
            ...dto
        });
    }

    public togglePostcard() {
        this.openPostcard = ! this.openPostcard;
        if (this.openPostcard === true ) {
            this.scrollPosition = window.scrollY;
        }
    }


}


export class PostcardView extends View<Postcard> {

    public constructor() {
        super({ template: template });
    }

    public init() {

        this.data.change.filter( (e)=> {
            return e.name === 'openPostcard'
        }).subscribe( (p)=> {

            if (p.newValue === true) {
                document.body.style.position = 'fixed';
                document.querySelector('.header').setAttribute('style', 'display:none')
            }
            else {
                document.body.style.position = '';
                document.body.style.top = '';
                document.querySelector('.header').removeAttribute('style');
                window.scrollTo(0, this.data.scrollPosition) ;
            }

        });
    }


}


// create model for data binding
export class DropDown extends Model {

    constructor( dto? ) {
        super( { selection:null, ...dto } );
    }
}
