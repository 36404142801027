import Model from 'ln/model/Model';
import {View, ViewConstructor} from 'ln/view/View';
import {setup} from 'ln/setup/Setup';
import {ViewDefinition} from 'ln/view/View';
import {StoryQuiz as template} from 'templates/components/storyquiz';
import { QuizPresenter } from 'components/quizpresenter/QuizPresenter';
//import {Quiz} from '@lernetz/lernfragen/components/quiz';
import {AudioService} from '../services/AudioService'

import Template from '@lernetz/ts-lib/src/core/view/Template';
import { renderer } from '@lernetz/ts-lib/src/core/view/Renderer';

import {node} from 'ln/node/Node';

// import {FullPage} from '../storypage/FullPage';
import {StoryPage} from '../storypage/StoryPage';
import { trackPageView } from 'typescript/Main';

export class StoryQuiz extends Model {
    public image:{ file_name?:string };
    // public index: number;
    public currentQuestionIndex: number;
    public currentQuestion: QuizPresenter;
    public questions:QuizPresenter[];
    public quizOpen: boolean;
    public quizState: string;
    // public fullpage: FullPage;
    public storypage: StoryPage;
    public isTeacher: boolean;
    public currentPosition: number;
    public storyTitle: string;
    public scrolledBottom: boolean;
    public title: string;


    public constructor(dto: object ) {
        super({
            index: null,
            currentQuestionIndex: 0,
            currentQuestion: null,
            quizOpen: false,
            quizState: 'unsolved',
            questions: [],
            ...dto
        });

        this.isTeacher = setup.data('data.isTeacher');
        this.questions.forEach( (question, index)=>  {
            //listen to next button on question item
            question.next.subscribe( (e) => {
                this.show( this.currentQuestionIndex + 1);
            });

            //listen on close event on quiz
            question.close.subscribe( (e) => {
                this.toggleQuiz();
            })
            question.change.subscribe( (e)=> {
                this.updatePointState(question);
            } )


            //update the quiz inputstate on the question item
            question.quiz.change.subscribe( (e) => {
                this.updateQuizState();
                //domique fragen:
                // in der view wird data-quizState="[[ quizState ]]" nicht richtig in den quiz-presenter übergeben
                question.quizState = this.quizState;
            });
        });

        this.change.filter(({ name }) => name === 'currentQuestion')
                   .subscribe(() => {
                    this.questions.forEach( (question, index)=>  {
                           question.isCurrent = question === this.currentQuestion;
                           question.isLast = this.questions.length -1 === index;
                    });
        });

        //needs to be at the end...
        this.currentQuestion = this.questions[this.currentQuestionIndex];
    }

    updatePointState( question) {
        if (question.evaluationState === 'correct') {
            question.pointState = 'correct';
        }
        else if (question.evaluationState === 'incorrect') {
            question.pointState = 'wrong';
        }
        else if ( question.isCurrent) {
            question.pointState = 'current';
        }
        else  {
            question.pointState = 'empty';
        }
    }
    updateQuizState() {
        let solved = this.questions.filter( (question) => {
            return question.quiz.inputState !== 'none';
        });

        if ( solved.length === this.questions.length ) {
            this.quizState = 'completed';
        }
        else {
            this.quizState = 'unsolved';
        }
    }

    toggleQuiz() {
        //on Quiz open
        if (this.quizOpen === false) {
            //save the current scrollposition, so we can go back on quiz close
            this.currentPosition = window.scrollY;
            //stop all audio
            AudioService.stopAll();
            //GA tracking if quiz opens
            //trackPageView(this.title);
        }
        this.quizOpen = !this.quizOpen;
    }

    public show( index ) {
        if (index >= (this.questions.length) || index < 0) {
            return;
        }
        this.currentQuestionIndex = index;
        this.currentQuestion = this.questions[ this.currentQuestionIndex];
    }
    //hack von dominique
    public getQuestionsForPointNavigation(questions, ...dependencies) {
        return questions;
    }
}

export class StoryQuizView extends View<StoryQuiz> {

    public constructor() {
        super({ template: template });
    }

    public init(){

        //overflow hidden on body for fullpage quiz
        this.data.change.filter( (p) => { return p.name ==="quizOpen"}).subscribe( (p) => {
            if (p.newValue === true) {
                document.body.style.position = 'fixed';
            }
            else {
                document.body.style.position = '';
                document.body.style.top = '';
                if ( this.data.quizState !== "completed" ) {
                    window.scrollTo(0, this.data.currentPosition) ;
                }
            }
        });


        //this is probably not very clever...
        this.data.questions.forEach( (question, index)=> {
            question.change
            .filter( (e) => {return e.name == 'solutionMode' || e.name=== 'pointState'})
            .subscribe( (e) =>{
                this.update( 'currentQuestion' );
            })
        });


    }

}


