
import { Signal } from '@lernetz/ts-lib/src/core/signal/Signal';
import Model from 'ln/model/Model';

import { ImageElement as template} from 'templates/components/elements';


import View from 'ln/view/View';


export  class ImageElement extends Model {

    public constructor(dto) {
        super({
            ...dto
        });
        
         
    }
}

export class ImageElementView extends View<ImageElement> {
    public constructor() {
        super({template: template })
    }
}